<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">客服中心</div>
      <div class="topRight"></div>
    </div>
    <div class="ewmImg">
        <img src="../../image/ewm.png" alt="">
    </div>
    <div class="size">
        在线客服
    </div>
    <div class="fooBtn" @click="btnEvent()">
        在线客服
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    btnEvent(){
        window.location.href = 'https://work.weixin.qq.com/kfid/kfca020097f10fea10c'
    }
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.ewmImg img{
  width:288px;
  height:288px;
  margin-top:190px;
  margin-left:231px;
}
.size{
  font-size: 28px;
  font-weight: 600;
  color: #0B1526;
  text-align: center;
  margin-top:40px;
}
.fooBtn{
  width: 684px;
  height: 88px;
  background: #0957C3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  text-align: center;
  line-height: 88px;
  font-size: 32px;
  font-weight: 600;
  color: #FFFFFF;
  position: fixed;
  bottom: 62px;
  right: 33px;
}
</style>
